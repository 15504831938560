import {default as versionInformation} from '../../../package.json'

export function Version() {
    return(
            <p>
                <em>
                    <small>
                        Version {versionInformation.version}
                    </small>
                </em>
            </p>
    )

}