export function Social() {
    return(
        <>
            <h1 className="display-2 text-center">
                <b>Links</b>
            </h1>
            <p className="lead text-center">
                Find me online.
            </p>
            <p className="text-center">
             <div className="btn-group btn-group-lg" role="group">
                <a href="https://www.twitch.tv/ruckusacademy" target="_blank" rel="noreferrer noopener" className="btn btn-outline-info">
                    Twitch
                </a>
                <a href="https://www.mixcloud.com/ruckusacademy/" target="_blank" rel="noreferrer noopener" className="btn btn-outline-info">
                    Mixcloud
                </a>
            </div>
            </p>
            

        </>
    )
}