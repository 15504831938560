export function Title() {
    return(
        <>
            <h1 className='display-1 text-center'>
               <b>Ruckus Academy</b> 
            </h1>
            <p className='lead text-center'>
                Come, remember how to dance.
            </p>
        </>
    )
}

