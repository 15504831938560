export function LastPerformance() {
  return (
    <>
      <h1 className="display-3 text-center">
        <b>Last Performance</b>
      </h1>
      <p className="lead text-center">
        One stop for the good stuff.
      </p>
      <div className="container">
        <iframe
          title="Last Performance"
          width="100%"
          height="200"
          src="https://www.mixcloud.com/widget/iframe/?hide_cover=1&feed=%2Fruckusacademy%2F">
        </iframe>
      </div>

    </>
  )
}

