import React from 'react'
import './app.scss';
import { LastPerformance } from './homepage/components/last-performance';
import { Social } from './homepage/components/social';
import { Title } from './homepage/components/title';
import { GlobalDataService } from './global-data/services/global-data.service';
import { Schedule } from './homepage/components/schedule/test-prop';
import { ScheduleItem } from './global-data/types/schedule-item';
import { Version } from './homepage/components/version';

interface AppState{
  schedule: ScheduleItem[]
}

export class App extends React.Component<any, AppState> {
  constructor(props: any) {
    super(props)
    this.state = {
      schedule: GlobalDataService.schedule
    }
  }

  render() {
    return (
      <>
      <div className="App">
        <Title/>
        <Social/>
        <div className="banner" />
        <LastPerformance/>
        <div className="banner" />
        <Schedule list={this.state.schedule}/>
      </div>
      <Version/>
    </>
    )
  }
}
